import { cn } from '@/utils/style';
import React from 'react';

type Props = {
  children: React.ReactNode;
  className?: string;
  testId?: string;
};

const ContentAreaWrapper: React.FC<Props> = ({
  className,
  children,
  testId,
  ...props
}) => {
  return (
    <div
      className={cn([
        'mx-auto max-w-7xl px-3 pt-3 md:mb-7 lg:mb-12 lg:px-8 lg:pt-8 2xl:max-w-8xl',
        className && className,
      ])}
      data-testid={testId}
      {...props}
    >
      {children}
    </div>
  );
};

export default ContentAreaWrapper;
